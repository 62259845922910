import Image1 from '../../public/images/pexels-mati-mango-2609925.jpg'
import Image2 from '../../public/images/pexels-đỗ-ngọc-tú-quyên-1520760.jpg'
import Image3 from '../../public/images/pexels-inimafoto-a-12410823.jpg'
import Image4 from '../../public/images/pexels-m-e-r-v-e-16765277.jpg'
import Image5 from '../../public/images/pexels-brett-sayles-5092809.jpg'

export const Data = [
    {
        id: 1,
        image: Image1,
        title: 'Belén',
        description: 'Hola chicos! Increible todo! Me halagaron un montón el catering!! De diez, super recomendable'
    },
    {
        id: 3,
        image: Image3,
        title: 'Horacio',
        description: 'Hola Gonza!! Todo increible! Los chicos son un 10, muchisimas gracias!!'
    },
    {
        id: 4,
        image: Image4,
        title: 'Florencia',
        description: 'Hola chicos! Salió todo perfecto!! Los chicos eran un amor y la pizza estaba riquísima!!'
    },
    {
        id: 5,
        image: Image5,
        title: 'Ramiro',
        description: 'Gonzalo, salió impecable todo, muchisimas gracias!! Las pizzas son espectaculares'
    },
    {
        id: 2,
        image: Image2,
        title: 'Andrea',
        description: 'Salió todo impecable! Me asusté un poquito que llegaron tarde pero salio perfectooo!!'
    },
]