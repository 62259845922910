import events from '../../public/images/events.webp'
import events2 from '../../public/images/events2.webp'
import events3 from '../../public/images/events3.webp'
import events4 from '../../public/images/events4.webp'
import events5 from '../../public/images/events5.webp'
import events6 from '../../public/images/events6.webp'
import events7 from '../../public/images/events7.webp'
import events8 from '../../public/images/slider.webp'

export const ProducData = [
    {
        image: events7,
    },
    {
        image: events,
    },
    {
        image: events2,
    },
    {
        image: events3,
    },
    {
        image: events4,
    },
    {
        image: events5,
    },
    {
        image: events6,
    },    
    {
        image: events8,
    },    
]



